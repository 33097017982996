$gutter: 20px;

#blog-archive .blog-img {
    min-height: 185px;
}

#blog-post .blog-img {
    min-height: 250px;
}

#blog-archive,
#blog-post {
    .Grid {
        justify-content: flex-start;
        padding: 0 $gutter $gutter 0;

        .Tile {
            padding: $gutter 0 0 $gutter;
        }

        .yoga-panel {
            margin-bottom: 0;
            padding: 0;

            .yoga-panel-body-item:not(:last-child) {
                border: 0;
            }
        }
    }
    .blog-img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .blog-content {
        padding: 0;
        & > div {
            padding: 2rem;
        }
        .post-title {
            margin: -2rem -2rem 1.5rem;
            padding: 2rem;
            font-size: 1.5em;
            background-color: #f7fafc;
            border-bottom: 1px solid #e2e8f0;

            a {
                color: $theme-title-color;
                transition: color 0.2s ease;
                &:hover {
                    text-decoration: none;
                    color: $colour-blue;
                }
            }
        }

        sub {
            color: $theme-description-color-lighter;
        }
    }

    .heading {
        .title {
            color: $theme-title-color;
            font-size: 36px;
            font-style: italic;
            font-weight: 600;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            text-align: center;
            margin: 0;
        }
    }
    .sidebar {
        // Apply a little margin between each div in the sidebar
        div:not(:first-child) {
            margin-top: $gutter;
        }

        .list {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li:not(:last-child) {
                padding-bottom: 10px;
            }

            li .fa {
                margin-right: 5px;
            }

            li a {
                text-decoration: none;
                color: #6c6c6c;

                &:hover {
                    color: #216a94;
                }
            }
        }
    }
}

@media screen and (min-width: $tablet) {
}

@media screen and (min-width: $desktop) {
    #blog-post {
        .Tile {
            max-width: 720px;
            margin: 0 auto;
        }
        .blog-img {
            min-height: 325px;
        }
    }
}
