.SingleVideoWrapper {
    margin-top: -30px;
    margin-bottom: 25px;

    #video-hook {
        width: 100%;

        & > iframe {
            width: 100%;
            height: 560px;
        }
    }
}

#video-info {
    position: relative;

    .video-title {
        p {
            font-size: 1.5em;
        }
    }

    .badges-container {
        .label {
            display: block;
            margin-top: 6px;
        }
    }

    .favourite-toggler {
        font-size: 24px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .description {
        color: $theme-text-color-darker;
    }
}

#video-show {
    .list-group-item.completed {
        color: #bfbfbf;
        font-style: italic;

        a {
            text-decoration: line-through;
            color: $colour-green;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

@media screen and (min-width: $tablet) {
    #video-info {
        .badges-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .label {
                margin-top: 0;
                &:not(:first-child) {
                    margin-left: 6px;
                }
            }
        }
    }
}
