.welcome {
    #personal-training {
        background-color: #f5f5f5;
        .brand-btn {
            background-color: #5075E3;
            font-size: 16px;
            &:hover {
                background-color: darken(#5075E3, 5%);
            }
        }
        h2 {
            position: relative;
            &::after,
            &::before {
                content: "";
                display: block;
                position: absolute;
                pointer-events: none;
            }
            &::before {
                top: 19px;
                left: -11px;
                // width: 298px;
                width: 92%;
                height: 33px;
                background-color: rgba(80, 227, 194, 0.31);
            }
            &::after {
                // width: 269px;
                width: 82%;
                height: 9px;
                right: -8px;
                bottom: -22px;
                background-color: rgba(208, 68, 159, 0.43);
            }
        }
        p {
            max-width: 330px;
            margin: 0 auto;
        }
        .Grid {
            .Tile:nth-child(1) {
                padding-bottom: calc(37px - 0.75rem);
            }
            .Tile:nth-child(2) {
                padding-bottom: calc(37px - 0.75rem);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

// --- Tablet ---
@media screen and (min-width: $tablet) {
    .welcome {
        #personal-training {
            .Grid {
                .Tile:nth-child(1) {
                    padding-bottom: calc(32px - 0.75rem);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    
                    p {
                        margin: 0;
                    }
                }
                .Tile:nth-child(2) {
                    padding-bottom: 0;
                    padding-left: calc(102px - (46px - 0.75rem) / 2);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }
}

// --- Desktop ---
@media screen and (min-width: $desktop) {
    
}
