.welcome {
    #what-i-do {
        background-color: white;
        padding-bottom: 2.25rem;
        .Grid {
            // max-width: $home-page-max-width;
            // margin: 0 auto;
            padding-right: 0;
            background: white;
            position: relative;
            overflow: hidden;
        }
        .main-content-tile {
            .green-rect {
                top: 50px;
                right: 10px;
            }
            .pink-rect {
                top: calc(50px + 43px + 28px);
                right: 35px;
            }
            h2 {
                padding-right: 35px;
                text-transform: uppercase;
                span {
                    font-size: 30px;
                    margin-right: -40px;
                }
            }
            p.first {
                margin-bottom: 19px;
            }
            p.second {
                margin-bottom: 27px;
            }
        }
        .img-tile {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            // svg {
            //     position: absolute;
            //     bottom: 0;
            //     right: -0.75rem;
            //     z-index: 1;
            // }

            .flex-img-wrapper {
                margin-left: 92px;
                z-index: 1;
            }
            img {
                width: 250px;
            }
        }
    }
}

// --- Tablet ---
@media screen and (min-width: $tablet) {
    .welcome {
        #what-i-do {
            .main-content-tile {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .green-rect {
                }
                .pink-rect {
                    right: 0;
                }
                h2 {
                    font-size: 40px;
                    text-align: right;
                    margin-top: calc(32px - 0.75rem);
                    padding-right: 51.5px;
                    span {
                        font-size: 34px;
                        margin-left: 0;
                        margin-right: -40px;
                    }
                }
                p {
                    max-width: 460px;
                }
                .brand-btn {
                    margin-right: 25px;
                }
            }
        }
    }
}

// --- Desktop ---
@media screen and (min-width: $desktop) {
    .welcome {
    }
}
