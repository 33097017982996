#edit-blog,
#create-blog {
    .form-controls-container {
        margin-top: 1.75em;
        .control-label {
            font-weight: bold;
            padding: 0;
        }
        .control {
            margin-top: 0.5em;
        }
    }
}
