.categoryList {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin-bottom: 5rem;

    li:not(:last-child) {
        margin-right: 5px;
    }
}
