$border-radius: 0.5em;

.stretched-tile {
    display: flex !important;
    align-items: stretch;
    flex-direction: column;

    .yoga-panel {
        width: 100%;
        flex-grow: 1;
    }
}

.yoga-panel {
    background: $theme-background-lighter;
    border-radius: $border-radius;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    transition: box-shadow 0.2s ease;
    padding: 30px 60px;
    margin-bottom: 3rem;
    color: $theme-color;

    &:hover {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    .yoga-panel-body {
    }

    .yoga-panel-body-item {
        &:not(:last-child) {
            border-bottom: 1px dashed #dfdfdf;
        }
        padding: 1rem 0;

        .title {
            font-size: 18px;
            color: $theme-title-color;
            margin: 0 0 1rem;

            a:hover {
                text-decoration: none;
            }
        }

        .description {
            font-size: 16px;
            margin: 0 0 0.6em;
            color: $theme-description-color;
        }

        .small-description {
            font-size: 14px;
            color: $theme-description-color-lighter;
            margin: 0;
        }
    }
}

.yoga-panel-heading {
    * {
        margin: 0;
        color: $theme-title-color;
        font-size: 1em;
    }
    padding-left: 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 20px;
    //    font-weight: 600;
}

.yoga-panel-sandwich {
    .yoga-panel-top {
        border-radius: $border-radius $border-radius 0 0;
    }

    .yoga-panel-middle {
        border-radius: 0;
    }

    .yoga-panel-bottom {
        border-radius: 0 0 $border-radius $border-radius;
    }
}
