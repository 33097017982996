.welcome {
    #pricing {
        background-color: darken(#f5f8fa, 2%);
        .main-content-tile {
            padding: 0.75rem;
            h2 {
                margin-top: 30px;
            }
        }
        .Grid {
            max-width: 280px;
            margin: 0 auto;
        }
        .pricing-card {
            display: flex;
            flex-direction: column;
            text-align: center;
            background-color: white;
            flex-basis: 100%;
            margin: 0 calc(15px - 0.75rem) 15px 15px;
            box-shadow: 0 2px 6px rgba(#000, 0.11);
            border-bottom: 5px solid #4a90e2;

            &:last-child {
                margin-bottom: calc(15px - 0.75rem);
            }

            .pre-separator {
                padding: 45px 0 35px;
                h2,
                p {
                    margin: 0;
                    font-weight: 600;
                }
                h2 {
                    font-size: 32px;
                    text-transform: uppercase;
                }
            }

            .separator {
                width: 73.3333%;
                height: 2px;
                margin: 0 auto;
                background-color: #4a90e2;
            }

            .post-separator {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                flex-grow: 1;
                padding: 15px 25px 45px;
                p {
                    width: 100%;
                    font-size: 12px;
                    margin-bottom: 20px;
                }
                .brand-btn {
                    font-size: 12px;
                    padding: 12px 18px;
                }
            }
        }
    }
}

@media screen and (min-width: $tablet) {
    .welcome {
        #pricing {
            .Grid {
                justify-content: center;
                max-width: none;
                margin: 0;
            }
            .pricing-card {
                flex-basis: auto;
                width: 220px;

                &:last-child {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
