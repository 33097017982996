#yoga-bubbles-footer {
    color: $theme-text-color;
    background-color: #fff;
    font-family: $font-montserrat;
    position: relative;
    padding: 2.5rem;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    & > .footer-col:not(:first-child) {
        margin-top: 3rem;
    }

    .footer-col {
        flex: 0 0 auto;
        width: 100%;
    }

    .footer-row {
        width: 100%;
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }

    .logo-and-social-links {
        .footer-logo > a > img {
            width: 100%;
            height: auto;
            max-width: 180px;
        }

        .rights-reserved {
            font-size: 12px;
        }

        .social-links {
            font-size: 2.6rem;
            a {
                text-decoration: none;
                color: darken($color: #a0aec0, $amount: 10);
                opacity: 0.7;
                transition: opacity 0.3s ease;
                &:not(:first-child) {
                    margin-left: 1rem;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .footer-link {
        color: #4a5568;
    }
}

@media screen and (min-width: 769px) {
    #yoga-bubbles-footer {
        .footer-col {
            width: auto;
            &:not(:first-child) {
                margin-top: 1rem;
                margin-left: 95px;
            }
        }

        .newsletter {
            width: 320px;
        }
    }
}
