#personal-session-container {
    .yoga-panel {
        .yoga-panel-heading {
            margin: 20px 0;
        }
        .yoga-panel-body {
            padding-top: 15px;
        }
    }
    .table {
        tr > td:first-child {
            text-align: right;
        }
        input[type="number"] {
            border: 0;
        }
    }
    .title {
        font-size: 36px;
        font-style: italic;
        font-weight: 600;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-top: calc(32px - 0.75rem);
        margin-bottom: 28px;
        color: #3C3C3C;
    }
}
