$item-spacing: 5px;

.video-catalog .header {
    margin-bottom: 5px;

    .title {
        margin: 0;
        font-size: 20px;
    }

    .icon-wrap {
        display: flex;
        align-items: center;

        .favourite-status {
            font-size: 26px;
            margin: 0 1rem;
            cursor: pointer;
        }

        .difficulty-level-image {
            width: 28px;
        }
    }

    .description {
        width: 100%;
    }
}

.video-catalog .yoga-panel-body-item.filter-item {
    padding: $item-spacing 0;
}

.video-catalog .yoga-panel-body-item {
    // Remove the dashed border as it doesn't work with revised version of video catalog
    &:not(:last-child) {
        border: 0;
    }
    &.striped {
        border-bottom: 1px dashed #dfdfdf;
    }
    .title.error {
        margin: 0;
        color: $colour-red;
    }
}

#filter-container {
    .collapsed {
        overflow: hidden;
        max-height: 16em;

        .expand-and-collapse-toggle {
            position: absolute;
            padding-top: 2em;
            margin-right: -40px;
            margin-left: -40px;
        }
    }
    .expand-and-collapse-toggle {
        bottom: 0;
        padding: 1em 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 30%,
            rgba(255, 255, 255, 0) 100%
        );
        height: 5em;
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;
    }
    .filter-controls {
        display: none;
    }
    .filters-visible {
        display: block;
    }
    .filters-visibility-toggler {
    }
    .filter-button-container {
        display: flex;
        justify-content: flex-end;
    }
    .filters-button {
        display: flex;
        align-items: center;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        padding-left: 0.75em;
        padding-right: 1em;
        border-radius: 0.5rem;
        background-color: $theme-background-lighter;
        border: 0;
        font-size: 16px;
        height: 40px;

        svg {
            width: 1.5em;
            height: 1.5em;
            color: #a0aec0;
            fill: currentColor;
        }

        &:hover {
            background-color: lighten($theme-background-lighter, 8%);
        }

        &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
        }
    }
}

@media screen and (min-width: $tablet) {
    .video-catalog .header {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .info-container {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
        }

        .title {
            margin: 0;
            font-size: 26px;
            flex-grow: 1;
        }

        .favourite-status {
            font-size: 26px;
            margin: 0 1rem;
            cursor: pointer;
        }

        .difficulty-level-image {
        }

        .description {
            width: 100%;
            margin-top: -10px;
        }
    }
}

@media screen and (min-width: $desktop) {
    #filter-container {
        .filters-visibility-toggler {
            display: none;
        }
        .filter-controls {
            display: block;
        }
    }
}

.filter-item {
    cursor: pointer;
    font-weight: lighter;
    font-size: 12px;
    margin: 0;
    color: #bfbfbf;

    &:hover {
        color: #797979;
        font-weight: bold;
    }

    .fa {
        font-size: 10px;
    }

    &.active {
        color: $colour-green;
        font-weight: bold;
        // font-size: 14px;
    }
}

.yoga-panel.filters {
    padding-left: 40px;
    padding-right: 40px;
}

.clear-filters {
    color: $colour-red;
    font-weight: bold;

    .fa {
        font-size: 12px;
    }

    &:hover {
        color: darken($colour-red, 12%);
    }
}
