$borderColor: #d8e4ed;
$backgroundColor: #ecf2f6;
$textColor: #6C6C6C;

.videoNav{
    margin-bottom: 2.45rem;
}

.videoNavList {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: $backgroundColor;
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
        box-sizing: border-box;
        border-style: solid;
        border-color: $borderColor;
        border-width: 0 1px 0 0;
        letter-spacing: 3px;

        &:first-child {
            border-width: 0 1px 0 1px;
        }

        a {
            padding: 10px 25px;
            background-color: $backgroundColor;
            border-radius: 0;
            color: $textColor;
        }

        a:hover {
            background-color: darken($backgroundColor, 5%);
            color: $textColor;
        }
    }
}
