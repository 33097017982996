.welcome {
    #about-me {
        background-color: #f5f8fa;
        position: relative;
        overflow: hidden;
        svg {
            position: absolute;
            bottom: -239px;
            right: 0;
        }
        .img-tile {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 43px;
            padding-bottom: 43px;

            .flex-img-wrapper {
                margin-right: 92px;
                z-index: 1;
            }
            img {
                width: 250px;
            }
        }
        .main-content-tile {
            h2,
            p {
                text-align: left;
            }
            p {
                max-width: 445px;
                margin-bottom: 16px;
            }
            .last-p {
                margin-bottom: 35px;
            }
            .brand-btn {
                margin-bottom: 35px;
            }
        }
    }
}

// --- Tablet ---
@media screen and (min-width: $tablet) {
    .welcome {
        #about-me {
            .main-content-tile {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0.75rem;
                h2 {
                    margin-bottom: 8px;
                }
            }
        }
    }
}

// --- Desktop ---
@media screen and (min-width: $desktop) {
    
}
