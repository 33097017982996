#login-page {
    justify-content: center;
    .Tile {
        max-width: $tablet;
    }

    .yoga-panel-heading {
        text-align: center;
    }
    .yoga-panel-body-item {
        padding: 3rem 0;

        table {
            p {
                margin: 0;
                span {
                    color: grey;
                }
            }
        }
    }
}
