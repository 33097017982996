$gutter: 20px;

$content-wrapper-background-color: $theme-background-lighter;

@if $theme == light {
    $content-wrapper-background-color: $theme-background-darker;
}

#dashboard {
    padding-left: 0px;
    padding-right: 0px;
    .Grid {
        padding: 0 $gutter $gutter 0;

        #main-tile {
            .yoga-panel {
                position: relative;
                text-align: center;
            }
            #dashboard-greeting {
                font-size: 1.25em;
                color: $theme-title-color;
                margin-top: 11px;
                letter-spacing: 0.1em;
                text-transform: uppercase;

                a {
                    font-size: 14px;
                }
            }
            .content-wrapper {
                position: relative;
                padding: 15px;
                border-radius: 6px;
                background-color: $content-wrapper-background-color;
                color: $theme-description-color;
                border: 0;

                p {
                    padding: 0;
                    margin: 0;
                    font-size: 3rem;
                    font-weight: 400;
                }
                .subtext {
                    color: $theme-description-color-lighter;
                    text-transform: uppercase;
                    font-size: 0.75em;
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    transition: all 0.2s ease-out;
                    border-radius: 6px;

                    .fa {
                        font-size: 4rem;
                        color: white;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        #completions-info {
            .content-wrapper {
                border-color: #66bb6a;
                .overlay {
                    background-color: #66bb6a;
                }
            }
        }
        #favourites-info {
            .content-wrapper {
                border-color: #f44336;
                .overlay {
                    background-color: #f44336;
                }
            }
        }
        #points-info {
            .content-wrapper {
                border-color: #4bbcd0;
                .overlay {
                    background-color: #4bbcd0;
                }
            }
        }
        #points-image {
            width: 75px;
            height: 75px;
            border: 0;
        }
    }

    #popular-videos {
        .yoga-panel {
        }
    }

    #favourite-videos {
        .remove {
            cursor: pointer;
            font-style: italic;
            color: #ef9a9a;
            &:hover {
                text-decoration: none;
                color: #e57373;
            }
        }
    }

    .Tile {
        padding: $gutter 0 0 $gutter;
    }
}

@media screen and (min-width: $tablet) {
    #dashboard {
        .Grid {
            #main-tile {
                #dashboard-greeting {
                    // font-size: 3.2rem;
                }
                .Tile {
                    .content-wrapper {
                        p {
                            // font-size: 4rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $desktop) {
    #dashboard {
        .Grid {
            .alert {
                width: 50%;
            }
            #main-tile {
                #dashboard-greeting {
                    // font-size: 3.2rem;
                }
                .Tile {
                    .content-wrapper {
                        p {
                            // font-size: 3rem;
                        }
                    }
                }
            }
        }
    }
}
