.guest {
    // Custom Nav bar for Guest only page
    .welcome-nav {
        position: absolute;
        z-index: 9;
        width: 100%;
        // max-width: $home-page-max-width;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 2rem 1.75rem;
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .brand-text {
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            margin: 0 0 22px;
            font-size: 3rem;
            font-family: "Olivier", "Petit Formal Script", cursive;
            text-align: center;
            a {
                color: #fff;
                &:hover {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
        .brand-bubbles {
            display: none;
            justify-content: center;
            align-items: center;
            img {
                margin-right: 3px;
                width: 55px;
                height: auto;
            }
            img:nth-child(3),
            img:nth-child(1) {
                width: 40px;
            }
        }

        .buttons {
            flex-basis: 100%;
            text-align: center;
            .btn {
                padding: 8px 15px;
                border: 0;
            }
            .login-btn {
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
                background: none;
                border: 0;
                border-radius: 0;
                border-bottom: 2px solid transparent;
                color: white;
                transition: all 0.2s ease-out;
                &:hover {
                    // text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
                    color: white;
                    border-bottom-color: white;
                }
            }
            .sign-up-btn {
                background: rgba(80, 117, 227, 0.55);
                color: white;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
                border: 0;
                padding: 9px 0;
                width: 100px;
                border-radius: 22px;
                &:hover {
                    background: rgba(80, 117, 227, 0.9);
                    color: white;
                }
            }
        }
    }
}

// --- Tablet ---
@media screen and (min-width: $tablet) {}

// --- Desktop ---
@media screen and (min-width: $desktop) {
    .guest {
        .welcome-nav {
            align-items: center;
            justify-content: space-between;

            .brand-text {
                flex-basis: 0;
                flex-grow: 1;
                margin: 0;
                text-align: left;
                justify-content: flex-start;
                padding-left: 25px;
            }
            .brand-bubbles {
                justify-content: center;
                flex-basis: 0;
                flex-grow: 1;
                display: flex;
            }
            .buttons {
                flex-basis: 0;
                flex-grow: 1;
                margin-top: 0;
                text-align: right;
                .login-btn {
                    margin-right: 8px;
                }
            }
        }
    }
}
