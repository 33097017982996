$brand-color: #0288d1;
$brand-hover-color: #0277bd;

@if $theme == light {
    $theme-text-color: #a0aec0;
}

.navbar-nav {
    margin: 0;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
    background-color: $theme-background-lighter;
}

.navbar-inverse {
    border: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: $theme-background-lighter;

    .navbar-brand {
        transition: all 0.3s ease-out;
        color: $brand-color;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);

        &:hover {
            color: $brand-hover-color;
        }

        h1 {
            font-family: "Olivier", "Petit Formal Script", "Open Sans", Helvetica, Arial, sans-serif;
            margin: 0;
            font-size: 26px;
        }
    }

    .navbar-nav > li > a:focus,
    .navbar-nav > li > a:hover {
        color: #3097d1;
    }
}

.navbar .dropdown-header {
    color: $theme-description-color;
    font-weight: 400;
}

.navbar-inverse .navbar-nav > li > a {
    color: $theme-text-color;
}

.nav-dropdown {
    ul.flex-container {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    ul.flex-container > div {
        padding: 2rem 2.5rem;

        &:not(:last-child) {
            // border-bottom: 2px dashed #e2e8ec;
        }

        &.have-a-question {
            background: #f7fafc;
            width: 100%;
            // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            border-radius: 6px;
        }

        .dropdown-header {
            padding: 0;
            font-size: 16px;
        }
        li {
            padding: 0.65rem 0;
        }
        li.nav-button {
            margin-top: auto;
            a {
                padding: 1rem 2.5rem;
                border-radius: 9999px;
            }
        }
        li > a {
            // padding: 2px 10px 2px 10px;
            // color: #4b5154;
            line-height: 22px;
            // font-weight: bold;

            // &.btn {
            //     padding-right: 23px;
            // }
        }
    }
}

@media screen and (min-width: $tablet) {
    .navbar {
        border-radius: 0;
    }

    .navbar-inverse .navbar-nav .main-nav-btn {
        margin: 0 15px 0 0;
    }

    .nav-dropdown {
        ul.flex-container {
            display: flex;
            flex-wrap: wrap;
            // padding: 25px 0;
        }
        ul.flex-container > div {
            // flex: 1 0 0;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;

            &.have-a-question {
                justify-content: center;
                align-items: center;
            }

            width: 50%;
            padding: 2.5rem;
            &:not(:last-child) {
                border-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: $desktop) {
    .nav-dropdown {
        ul.flex-container > div {
            width: 25%;
            &:not(:last-child) {
                border-bottom: 0;
            }
        }
    }
}
