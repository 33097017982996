@import "guest/navbar";
@import "guest/hero";
@import "guest/what_i_do";
@import "guest/cta_personal_training";
@import "guest/about_me";
@import "guest/pricing";

.welcome {
    // Shared among several sections on the home page
    .main-content-tile {
        position: relative;
        overflow: hidden;
        padding-left: calc(46px / 2);
        padding-right: calc((46px - 0.75rem) / 2);
        padding-bottom: calc(74px - 0.75rem);
        svg {
            position: absolute;
            z-index: 1;
        }
        h2 {
            font-size: 36px;
            font-style: italic;
            font-weight: 600;
            text-shadow: 0 2px 4px rgba(#000, 0.3);
            text-align: center;
            margin-top: calc(32px - 0.75rem);
            margin-bottom: 28px;
        }
        p {
            font-size: 18px;
        }
    }

    .background-div {
        width: 100%;
        height: 100%;
        p {
            margin: 0;
        }
    }

    .modal-dialog {
        width: 100%;
        max-width: 720px;
        iframe {
            width: 100%;
        }
    }

    .edit-link {
        font-size: 16px;
        font-weight: 400;
        text-shadow: none;
    }
}

// --- Tablet ---
@media screen and (min-width: $tablet) {
    .welcome {
        .main-content-tile {
            padding-left: calc(46px - 0.75rem);
            padding-bottom: calc(74px - 0.75rem);
        }
    }
}

// --- Desktop ---
@media screen and (min-width: $desktop) {
    .welcome {
    }
}
