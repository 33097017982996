.Grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 0.75rem 0.75rem 0;
    align-items: stretch;

    .Tile {
        display: block;
        padding: 0.75rem 0 0 0.75rem;
        width: 100%;

        &.is-one-third-mobile {
            width: 33.3333%;
        }
        &.is-two-thirds-mobile {
            width: 66.6666%;
        }
        &.is-half-mobile {
            width: 50%;
        }
    }
}

@media screen and (min-width: $tablet) {
    .Grid {
        .Tile {
            &.is-one-third-tablet {
                width: 33.3333%;
            }
            &.is-two-thirds-tablet {
                width: 66.6666%;
            }
            &.is-half-tablet {
                width: 50%;
            }
        }
    }
}

@media screen and (min-width: $desktop) {
    .Grid {
        .Tile {
            &.is-one-third-desktop {
                width: 33.3333%;
            }
            &.is-two-thirds-desktop {
                width: 66.6666%;
            }
            &.is-half-desktop {
                width: 50%;
            }
        }
    }
}
