.guest {
    .Hero {
        position: relative;
        width: 100%;
        // max-width: $home-page-max-width;
        // margin: 0 auto;
        padding: 150px 15px 50px;
        background-color: white;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        overflow: hidden; // otherwise scaling SVG breaks things
        text-align: center;

        svg {
            position: absolute;
            top: 60px;
            left: 45px;
            transform: scale(1.5, 1.5);
        }

        .edit-link {
            color: white;
        }

        #big-logo {
            font-family: $font-montserrat;
            color: white;
            z-index: 2;
            font-style: italic;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            text-align: left;
            h2 {
                font-size: 32px;
                font-weight: 600;
            }
            p {
                font-size: 24px;
                font-weight: 400;
                max-width: 400px;
            }
        }
    }

    #about-hero-container {
        transform: scaleX(-1);
        filter: FlipH;
    }
}

// --- Tablet ---
@media screen and (min-width: $tablet) {
}

// --- Desktop ---
@media screen and (min-width: $desktop) {
    .guest {
        .Hero {
            padding-top: 135px;
            padding-bottom: 135px;
            #big-logo {
                padding-left: 25px;
            }
        }
        .Hero#about-hero-container {
            padding: 150px 15px 50px;
        }
    }
}
