$main-container-background-color: $theme-background-darker;

@if $theme == light {
    $main-container-background-color: $theme-background;
}

body,
html {
    font-family: $font-montserrat;
    color: #313131;
    height: auto;

    // Background color depends on theme
    .main-container {
        background-color: $main-container-background-color;
    }
}

.yoga-panel {
    padding: 15px 25px;
    .image-wrapper {
        font-size: 1.5rem;
        text-transform: none;
        // text-align: center;
        margin-bottom: 1.75rem;
    }
    .links-wrapper {
        p {
            padding: 0.75rem 0 0;
        }
        a {
            color: #636b6f;
            &:hover {
                color: rgb(51, 141, 177);
                text-decoration: none;
            }
        }
    }
}

.row-wrap {
    .image-container {
        position: relative;
        .video-thumb-img {
            display: block;
            width: 100%;
            height: auto;
            padding: 2px;
            margin-bottom: 1rem;
            border: 1px solid #d3e0e9;
            border-radius: 7px;
            &:hover {
                border: 2px solid #7ccfdd;
                padding: 1px;
            }
        }
        .video-difficulty-img {
            width: 28px;
            position: absolute;
            top: 1rem;
            right: 1rem;
            opacity: 0.87;
        }
    }
    .info-container {
        position: relative;
        overflow-x: hidden;
        .title {
            white-space: nowrap;
            margin: 0;
            :first-child {
                margin-right: 8px;
            }
        }
        .duration {
            width: 100%;
            margin: 0;
        }
    }
}

@media screen and (min-width: $tablet) {
    .row-wrap {
        display: flex;
        .image-container {
            margin-right: 1em;
            margin-bottom: 0;
            .video-thumb-img {
                width: 125px;
            }
        }
        .info-container {
        }
    }
}

.brand {
    font-family: "Olivier", "Petit Formal Script", Helvetica, Arial, sans-serif;
    font-size: 2.2rem;
}

.brand-btn {
    display: inline-block;
    background: rgba(80, 117, 227, 0.7);
    color: white;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border: 0;
    padding: 15px 25px;
    border-radius: 30px;
    &:hover,
    &:focus {
        background: rgba(80, 117, 227, 0.9);
        color: white;
    }
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.btn.active,
.btn:active {
    box-shadow: none;
}

.adminUtilsContainer {
    .btn {
        margin-bottom: 2.3rem;
    }
}

// Icon styling - shared between views/components
$favourite-icon-color: #e37979;
.favourite-toggler {
    display: inline-block;
    cursor: pointer;
    .fa {
        transition: color 0.1126s ease-out;
    }
}
.remove-favourite > .fa.fa-heart {
    color: $favourite-icon-color;
}
.remove-favourite:hover > .fa.fa-heart {
    color: rgba($favourite-icon-color, 0.6);
}
.add-favourite > .fa.fa-heart-o {
    color: rgba($favourite-icon-color, 0.725);
}
.add-favourite:hover > .fa.fa-heart-o {
    color: $favourite-icon-color;
}

.fa {
    &.fa-star {
        color: #e9d675;
    }

    &.fa-clock-o {
        // color: $colour-blue;
    }

    &.fa-tags {
        // color: ;
    }

    &.fa-lock {
        // color: #ab9a43;
        // color: $colour-red;
    }
    &.fa-heart-o,
    &.fa-heart {
        color: $colour-red;
    }
}

.error {
    color: $colour-red;
}

.js-cookie-consent.cookie-consent {
    min-height: 70px;
    position: fixed;
    padding: 1.5rem 0.75rem;
    top: 0;
    left: 0;
    width: 100%;
    background: beige;
    z-index: 1040;
    text-align: center;

    button {
        display: inline-block;
        color: white;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        border: 0;
        padding: 5px 25px;
        border-radius: 30px;
        margin-left: 15px;
        background: cadetblue;
    }
}

/* Bootstrap notification overrides */
.alert {
    border-radius: 0.5rem;
    border-width: 3px;
    padding: 2rem;
}

/* Bootstrap label overrides */
.label {
    border-radius: 9999px;
    padding: 8px 16px;
    font-size: 0.75em;
    text-transform: uppercase;
}

// Bootstrap list group item override
.list-group-item {
    background-color: $theme-background-lighter;
}

@media screen and (min-width: $tablet) {
    .js-cookie-consent.cookie-consent {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        button {
        }
    }
}
