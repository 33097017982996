// Basic colors
$colour-green: #44dd8a;
$colour-red: #e37979;
$colour-blue: #4bbcd0;

// dark or light
$theme: light;

// Background colors
$theme-background: #FCFCFC;
$theme-background-lighter: white;
$theme-background-darker: #f1f1f1;

// Text colors
$theme-color: #131313;
$theme-text-color: $theme-color;
$theme-text-color-lighter: #7d97ad;
$theme-text-color-darker: #0e0e0e;
$theme-title-color: #2e3d49;
$theme-description-color: #7d97ad;
$theme-description-color-lighter: #9cb2c4;

// Overwrite if dark theme.
@if $theme == dark {
    // Backgrounds
    $theme-background: #2d3748;
    $theme-background-lighter: #4a5568;
    $theme-background-darker: #1a202c;

    // Text colors
    $theme-color: #f8f8f8;
    $theme-text-color: $theme-color;
    $theme-text-color-lighter: #ffffff;
    $theme-text-color-darker: #8795a1;
    $theme-title-color: #f2f2f2;
    $theme-description-color: #eee;
    $theme-description-color-lighter: rgba(255, 255, 255, 0.6);
}

// Max width restriction for (guest) home page.
$home-page-max-width: 1024px;

$tablet: 768px;
$desktop: 992px;

// Fonts
$font-montserrat: "Montserrat", Helvetica, Arial, sans-serif;
